import React from "react";
import ForgotPassword from "../../components/forgotPassword/ForgotPassword";

const ForgotPasswordScreen = () => {
  return (
    <>
      <ForgotPassword />
    </>
  );
};

export default ForgotPasswordScreen;
