import React from "react";
import NewPassword from "../../components/newPassword/NewPassword";

const NewPasswordScreen = () => {
  return (
    <>
      <NewPassword />
    </>
  );
};

export default NewPasswordScreen;
