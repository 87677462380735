export const ERR_MSGS = {
    Center_Name_Length: "Please enter a valid name. Max length 100 characters",
    City_Select: "Select a city",
    Invalid_Email: "The email entered is invalid",
    Invalid_Password: "The password entered is invalid",
    Invalid_Password_Pattern: "The password pattern is invalid",
    Invalid_Phone_Number: 'Invalid phone number',
    Invalid_Web_Url: 'Invalid web url',
    Login_Error: "Incorrect email or password",
    Name_length: "Please enter a valid name. Max length 50 characters",
    Password_Pattern_Hint: "The password should contain:\n1. At least 8 characters\n2. At least 1 uppercase character\n3. At least 1 lowercase character\n4. At least 1 special character",
    Password_Does_Not_Match: "Password doesn't match",
    Phone_Number_Length: "Please enter a valid contact number. Max length 15 characters",
    Postal_Code: "Postal Code is Invalid",
    Registration_Details: "Verify the entered details and try again",
    State_Select: "Select a state",
}