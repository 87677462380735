import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
    <>
    <div className="">
            <span className="text-lg text-secondaryVariant font-medium">
              Effective Date: November 1, 2023
            </span>
        </div>
        <div className=" pt-4 ">
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              1. Introduction
            </h2>
            <hr className="my-3" />
            <p className="text-base text-secondaryVariant font-normal">
              Welcome to Child Care Forward!   We value your privacy and are
              committed to protecting your personal information. This Privacy
              Policy outlines how we collect, use, and safeguard your
              information when you visit our Site or use our services.
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              2. Information We Collect
            </h2>
            <hr className="my-3" />
            <p className="text-base text-secondaryVariant font-normal">
              We may collect the following types of information:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>
                  Personal Information: Name, email address, phone number, and
                  other identifying information you provide when registering or
                  using our services. 
                </li>
                <li>
                  Usage Data: Information about how you interact with our Site,
                  including IP address, browser type, pages visited, and time
                  spent on those pages. 
                </li>
                <li>
                  Cookies: Small data files stored on your device to enhance
                  your browsing experience. You can manage cookie preferences in
                  your browser settings. 
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              3. How We Use Your Information
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We use your information for various purposes, including:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>To provide and maintain our services. </li>
                <li>To notify you about changes to our services. </li>
                <li>
                  To allow you to participate in interactive features when you
                  choose to do so. 
                </li>
                <li>To provide customer support. </li>
                <li>
                  To gather analysis or valuable information so that we can
                  improve our services. 
                </li>
                <li>To monitor the usage of our Site. </li>
                <li>To detect, prevent, and address technical issues. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              4. Sharing Your Information
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except to provide our services or
              as required by law. We may share information with:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>
                  Service providers who assist us in operating our Site and
                  services. 
                </li>
                <li>Compliance with legal obligations. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              5. Data Security
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We implement appropriate security measures to protect your
              personal information. However, no method of transmission over the
              internet or electronic storage is 100% secure.
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              6. Your Rights
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              You have the right to:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>Access the personal information we hold about you. </li>
                <li>Request correction of any inaccurate data. </li>
                <li>Request deletion of your personal information. </li>
                <li>Withdraw consent to processing your data. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              7. Changes to This Privacy Policy
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new policy on this page.
            </p>
          </div>
          <div className="mb-4 md:mb-16">
            <h2 className="text-2xl text-secondary font-semibold">
              8. Contact Us
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              If you have any questions about this Privacy Policy, please
              contact us at:
              <br />
              <Link
                to={`mailto:frontdesk@childcareforward.com`}
                className="font-medium"
              >
                frontdesk@childcareforward.com 
              </Link>
            </p>
          </div>
          </div>
    
    </>
  )
}

export default PrivacyPolicy