import { useSelector } from "react-redux";
import { RootState, store } from "../store/store";

export const getMessage = (errorLabel: string) => {
  const storedLabels = store?.getState().label;
  const messageToReturn = storedLabels?.Labels?.find(
    (label:any) => label.label === errorLabel
  );
  return messageToReturn?.message;
};
