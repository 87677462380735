import React from "react";
import { useLocation } from "react-router-dom";

const EmailSent: React.FC = (props: any) => {
  const email = useLocation().state.email
  return (
    <>
      <div className="flex justify-center items-center h-screen py-5 px-3">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[614px]">
            <h3 className="text-secondary text-xl sm:text-2xl font-semibold mb-4">
              Recover Your Account
            </h3>
            <p className="text-base font-light text-secondaryVariant text-opacity-80 mb-4">
              We've sent a password reset link to your email <span className="font-bold">{email}</span>.
            </p>
            <p className="text-base font-light text-secondaryVariant text-opacity-80">
              Please check your inbox and follow the instructions to create a new password.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;
