import React from 'react'
import CreateAccountForm from '../../components/createAccount/CreateAccountForm'

const CreateAccount = () => {
  return (
      <>
      <CreateAccountForm/>
      </>
)
}

export default CreateAccount