import React, { useState } from "react";
import UnlockExclusiveBenefits from "../../../components/Registration/UnlockExclusiveBenefits";
import { Link } from "react-router-dom";
import RegistrationOption from "../../../components/Registration/RegistrationOption";
import { QUERY_PARAMS_KEY } from "../../../constants/keys";
import { USER_ROLES } from "../../../constants/roles";

const RegisterOption = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI) as string;
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID) as string;
  const role = urlParams.get(QUERY_PARAMS_KEY.ROLE) as string;
  const [proceedingRole, setProceedingRole] = useState(role || USER_ROLES?.CENTER);
  const handleRegisterOptionSelect = (role: string) => {
    setProceedingRole(role);
  };
  return (
    <>
      {/* <div> */}
      {/* <div className="flex justify-center py-4"></div> */}
      <div className="flex justify-center items-center h-full md:h-[calc(100vh-135px)] p-3">
        <div className="bg-[#F6F6F6] rounded-xl p-5 md:p-8 lg:p-12 flex flex-col md:flex-row md:justify-center md:items-center gap-8 lg:gap-16 max-w-[995px]">
          <div className="basis-full md:basis-3/5">
            <UnlockExclusiveBenefits proceedingRole={proceedingRole}/>
          </div>
          <div className="basis-full md:basis-2/5">
            <RegistrationOption
              redirectURI={redirectURI}
              clientID={clientID}
              proceedingRole={proceedingRole}
              handleRegisterOptionSelect={handleRegisterOptionSelect}
            />
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default RegisterOption;
