import React from "react";
import VerifyYourEmail from "../../components/verifyYourEmail/VerifyYourEmail";

const VerificationEmail = () => {
  return (
    <>
      <VerifyYourEmail />
    </>
  );
};

export default VerificationEmail;
