import { jwtDecode } from "jwt-decode";
import { STORAGE_KEYS } from "../constants/keys";
import { ITokens } from "../types/ITokens.interface";

export const storeTokens = (tokens: ITokens, rememberMe?: boolean) => {
  const { access_token, refresh_token } = tokens;
  if (access_token) localStorage.setItem(STORAGE_KEYS.ACC_TOKEN, access_token);
  if (refresh_token && rememberMe) localStorage.setItem(STORAGE_KEYS.RF_TOKEN, refresh_token);
  if (rememberMe) localStorage.setItem(STORAGE_KEYS.REMEMBER_ME, JSON.stringify(rememberMe));
};
export const storeUser = (accToken: string) => {
  const user = accToken && jwtDecode(accToken);
  if (user) {
    localStorage.setItem(STORAGE_KEYS.ACC_TOKEN, accToken);
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
  }
};
