import { jwtDecode } from "jwt-decode";
import { STORAGE_KEYS } from "../constants/keys";

export const checkForTokensExpiry = async (redirectURI: string) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN);
  const decodedAccToken = accToken ? jwtDecode(accToken) : undefined;

  if (decodedAccToken) {
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    if (Number(decodedAccToken.exp) < currentTime) {
      // Access token is expired, check refresh token
      const refToken = localStorage.getItem(STORAGE_KEYS.RF_TOKEN);
      const decodedRefToken = refToken ? jwtDecode(refToken) : undefined;

      if (decodedRefToken) {
        if (Number(decodedRefToken.exp) < currentTime) {
          // Refresh token is also expired, user needs to log in again

          // return "Sign In";
          return { rfToken: false };
        }

        // Handle refreshing access token using the refresh token
        // Implement refresh token logic here
        console.log(
          "Access token is expired, but refresh token is valid, refresh access token"
        );

        return { rfToken: true };
      }

      // No refresh token found, user needs to log in again
      console.log(
        "Access token is expired and no refresh token found, user needs to log in again"
      );
      return { accToken: false };
    }

    // Access token is still valid
    console.log("Access token is still valid");
    // const res =
    return { accToken: true };
  }

  // No access token found, user needs to log in again
  console.log("No access token or ref token found, user needs to log in");
  return;
};
