import React, { useEffect } from 'react'
import { IoCloseOutline } from 'react-icons/io5';

const CustomModal = ({ isOpen, onClose, title, children }: any) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, onClose]);

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 h-screen py-3 ">
      <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-lg w-full max-w-[900px] max-h-96 ">
        <div className="flex items-end justify-end h-12">
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 text-black rounded "
          >
            <IoCloseOutline size={32} />
          </button>
        </div>
        <div className='max-h-72 overflow-y-auto cstScroll'>
          <h2 className="text-4xl text-secondary font-semibold mb-4">{title}</h2>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default CustomModal