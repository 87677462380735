import React from 'react'
import EmailSent from '../../components/emailSent/EmailSent'

const EmailSentScreen = () => {
  return (
      <>
          <EmailSent/>
      </>
  )
}

export default EmailSentScreen