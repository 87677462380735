import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { TbCamera } from "react-icons/tb";
import Input, {
  isPossiblePhoneNumber
} from "react-phone-number-input/input";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ERR_MSGS } from "../../constants/errorMsgs";
import {
  initRegistrationErrorForm,
  initRegistrationForm,
} from "../../constants/initialForms";
import { QUERY_PARAMS_KEY, STORAGE_KEYS } from "../../constants/keys";
import { regexPatterns } from "../../constants/regexPatterns";
import {
  completeProfile,
  fetchAccountInfo,
  postFiles,
  verifyEmail,
} from "../../services/auth.service";
import { RootState } from "../../store/store";
import RequiredAsterisk from "../ui/icons/RequiredAsterisk";
import { convertToE164 } from "../../utils/functions";

const CompletingRegistration = () => {
  const [completeProfileForm, setCompleteProfileForm] =
    useState(initRegistrationForm);
  const [completeProfileFormErrors, setCompleteProfileFormErrors] = useState(
    initRegistrationErrorForm
  );
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { User } = useSelector((state: RootState) => state?.auth);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get("redirect_uri"); //from email
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const paramToken = urlParams.get("token");
  const cancelURI = urlParams.get("cancel");
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const location = useLocation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    if (name === "postalCode" && !regexPatterns.postalCode.test(value)) {
      return;
    }
    setCompleteProfileForm((prev) => ({ ...prev, [name]: value }));
    // setCompleteProfileFormErrors((prev) => ({ ...prev, createAccountError: false }));
  };

  const handleCheckBox = (check: boolean) => {
    setCompleteProfileForm((prev) => ({ ...prev, agreedToTerms: check }));

  }

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;

    const fieldMappings: { [key: string]: string } = {
      fullName: "fullNameError",
      phoneNumber: "phoneNumberError",
      postalCode: "postalCodeError",
      city: "cityError",
    };
    const errorKey = fieldMappings[name];
    if (errorKey) {
      setCompleteProfileFormErrors((prev) => ({
        ...prev,
        [errorKey]: "",
      }));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: { key: string; errorMsg: string } } =
    {
      fullName: {
        key: "fullNameError",
        errorMsg: ERR_MSGS?.Name_length,
      },
      phoneNumber: {
        key: "phoneNumberError",
        errorMsg: ERR_MSGS.Phone_Number_Length,
      },
      postalCode: {
        key: "postalCodeError",
        errorMsg: ERR_MSGS?.Postal_Code,
      },
    };

    const fieldMapping = fieldMappings[name];
    if (fieldMapping) {
      const { key, errorMsg } = fieldMapping;
      const { value } = event?.target;
      const regexPatternFind: { [key: string]: RegExp } = {
        fullName: regexPatterns?.name,
        phoneNumber: regexPatterns?.phoneNumber,
        postalCode: regexPatterns?.postalCodeLength,
        // city: regexPatterns?.city,
      };

      if (name in regexPatternFind && !regexPatternFind[name].test(value)) {
        setCompleteProfileFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
      }
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    let uploadedImagePath: string = "";
    if (file) {
      uploadedImagePath = await uploadFile();
      if (!uploadedImagePath) return;
    }
    const reqBody = {
      name: completeProfileForm?.fullName,
      contactNumber: completeProfileForm?.phoneNumber,
      postalCode: completeProfileForm?.postalCode,
      city: completeProfileForm?.city,
      profilePictureUrl:
        uploadedImagePath || completeProfileForm?.profilePictureUrl,
    };
    const res = await completeProfile(
      redirectURI as string,
      clientID as string,
      reqBody
    );
    if (res?.ok) {
      // if (USER_ROLES.CENTER === role) {
      //   localStorage.removeItem(STORAGE_KEYS.ROLE);
      //   navigate(
      //     `/complete-center-registration?${clientID ? "client_id=" + clientID : ""
      //     }${redirectURI && "&redirect_URI=" + redirectURI}`,
      //     { state: { authCode: res?.data?.code } }
      //   );
      //   return;
      // }
      localStorage.removeItem(STORAGE_KEYS.ROLE);
      window.location.replace(
        `${redirectURI}${"?authCode=" + res?.data?.code}`
      );
    }
  };

  const handleCancel = () => {
    window.location.replace(`${cancelURI}`);
  };

  const handleSkip = () => {
    navigate(
      `/sign-in${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`
    );
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("files", file as File);
    const res: any = await postFiles(formData);
    if (res?.ok) {
      setCompleteProfileForm((prev) => ({
        ...prev,
        profilePictureUrl: res?.data?.urls[0],
      }));
      return res?.data?.urls[0];
    } else {
      toast.error("Couldn't upload image.");
      return false;
    }
  };

  const handleImageChange = (e: any) => {
    setFile(e.target.files[0]);
    const tempFile = e.target.files[0];
    if (tempFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(tempFile);
    }
  };

  useEffect(() => {
    if (location?.pathname === "/edit-user-profile") {
      setIsEditing(true);
    }
  }, []);

  //to set the data of user into form state to edit
  useEffect(() => {
    // if (!User?._id) return
    getAccountInfo();
  }, []);

  const getAccountInfo = async () => {
    const res = await fetchAccountInfo();
    if (res?.ok) {
      setCompleteProfileForm((prev) => ({
        ...prev,
        fullName: res?.data?.name,
        phoneNumber: convertToE164(res?.data?.contactNumber,res.data.prevUser),
        profilePictureUrl: res?.data?.profilePictureUrl,
        postalCode: res?.data?.postalCode,
        city: res?.data?.city,
      }));
    }
  };

  useEffect(() => {
    const { city, profilePictureUrl, agreedToTerms, ...filteredProfileForm } =
      completeProfileForm;

    const isFieldEmpty = Object.values(filteredProfileForm).some(
      (value) => value === ""
    );

    const noError = Object.values(completeProfileFormErrors).every(
      (value) => value === ""
    );
    const isWrongPhoneNumber =
      completeProfileForm?.phoneNumber &&
      !isPossiblePhoneNumber(completeProfileForm?.phoneNumber);
    if (!isFieldEmpty && noError && !isWrongPhoneNumber && (isEditing || agreedToTerms)) setIsDisabled(false);
    else setIsDisabled(true);
  }, [completeProfileForm, completeProfileFormErrors]);

  useEffect(() => {
    if (!isEditing || !User?._id) return;
    setCompleteProfileForm((prev) => ({
      ...prev,  
      fullName: User?.name || "",
      phoneNumber: User?.contactNumber || "",
      postalCode: User?.postalCode || "",
      city: User?.city || "",
    }));
  }, [User]);

  useEffect(() => {
    if (!paramToken) return;
    const decodedToken: any = jwtDecode(paramToken);
    setCompleteProfileForm((prev) => ({ ...prev, email: decodedToken?.email }));
    verifyEmail(paramToken);
  }, [paramToken]);


  console.log("check form: ", completeProfileForm);
  return (
    <>
      <div className="flex justify-center items-center min-h-screen overflow-y-auto px-3 md:px-5 py-5">
        <div className="py-5">
          <img
            className="mb-4 md:mb-9"
            src="./images/web-logo.png"
            alt="web-logo"
          />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[920px]">
            <h3 className="text-secondary text-xl md:text-2xl font-semibold mb-4 capitalize">
              {isEditing
                ? "Edit Profile"
                : `Complete your profile as a ${(role as string) || ""}`}
            </h3>
            <form onSubmit={onSubmit}>
              <div className="mb-4 md:mb-8">
                <img
                  src={
                    previewUrl ||
                    completeProfileForm?.profilePictureUrl ||
                    "./images/empty-profile-img.png"
                  }
                  alt="profile"
                  className="max-w-[110px] md:max-w-[175px] max-h-[110px] md:max-h-[175px] mb-4"
                />
                <label className="text-base text-secondary border border-secondaryVariant2 flex items-center gap-2 bg-white rounded-full py-[5px] px-4 cursor-pointer w-max">
                  <TbCamera className="w-6 h-6" />
                  Upload/update{" "}
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                </label>{" "}
              </div>
              <div className="flex flex-col md:flex-row gap-4 md:gap-5">
                <div className="basis-full md:basis-1/2">
                  <label className="text-base text-secondaryVariant">
                    Full Name
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Alex"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    value={completeProfileForm?.fullName}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  {completeProfileFormErrors?.fullNameError && (
                    <p className="text-red-600 pl-3">
                      {completeProfileFormErrors?.fullNameError}
                    </p>
                  )}
                </div>
                <div className="basis-full md:basis-1/2">
                  <label className="text-base text-secondaryVariant">
                    Phone Number
                    <RequiredAsterisk />
                  </label>
                  <Input
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    country="US"
                    placeholder="(123) 456-7890"
                    value={completeProfileForm?.phoneNumber}
                    onChange={(value) => {
                      setCompleteProfileForm((prev) => ({
                        ...prev,
                        ["phoneNumber"]: value || "",
                      }));
                    }}
                    error={
                      completeProfileForm?.phoneNumber &&
                        !isPossiblePhoneNumber(completeProfileForm?.phoneNumber)
                        ? "Invalid phone number"
                        : undefined
                    }
                  />
                  {completeProfileForm?.phoneNumber &&
                    !isPossiblePhoneNumber(
                      completeProfileForm?.phoneNumber
                    ) && (
                      <p className="text-red-600 pl-3">
                        {ERR_MSGS.Invalid_Phone_Number}
                      </p>
                    )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 md:gap-5 mb-4">
                <div className="basis-full md:basis-1/2">
                  <label className="text-base text-secondaryVariant">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    placeholder="Chicago"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    value={completeProfileForm?.city}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  {completeProfileFormErrors?.cityError && (
                    <p className="text-red-600 pl-3">
                      {completeProfileFormErrors?.cityError}
                    </p>
                  )}
                </div>
                <div className="basis-full md:basis-1/2">
                  <label className="text-base text-secondaryVariant">
                    ZIP code
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    placeholder="Enter postal code (e.g. 54000)"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    value={completeProfileForm?.postalCode}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  {completeProfileFormErrors?.postalCodeError && (
                    <p className="text-red-600 pl-3">
                      {completeProfileFormErrors?.postalCodeError}
                    </p>
                  )}
                </div>
              </div>
              {!isEditing && (
                <div className="flex items-center gap-2 mb-4">
                  <label
                    className="relative flex items-center rounded-full cursor-pointer"
                    htmlFor="check"
                  >
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-secondary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-5 before:w-5 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity checked:border-secondary checked:bg-secondary"
                      id="check"
                      name="agreedToTerms"
                      onChange={(e) => { handleCheckBox(e?.target?.checked) }}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="1"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <span className="text-base text-secondaryVariant">
                    By submitting this form, you agree to abide by the terms and
                    conditions of the childcare platform.
                  </span>
                </div>
              )}
              <div className="flex items-center gap-3">
                <button
                  className={`btnPrimary max-w-[156px] ${isDisabled ? "opacity-50" : ""
                    }`}
                  type="submit"
                  disabled={isDisabled}
                >
                  Complete
                </button>
                {/* this skip button not show on center Registration screen */}
                {!isEditing && (
                  <button
                    className={`btnSimple max-w-[86px] ${!completeProfileForm?.agreedToTerms ? "opacity-50" : ""}`}
                    type="button"
                    onClick={handleSkip}
                    disabled={!completeProfileForm?.agreedToTerms}
                  >
                    Skip
                  </button>
                )}
                {isEditing && (
                  <button
                    className="btnSimple max-w-[156px]"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default CompletingRegistration;
