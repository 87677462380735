import React from "react";
import { useLocation } from "react-router-dom";
import { resendVerifyLink } from "../../services/auth.service";
import { QUERY_PARAMS_KEY } from "../../constants/keys";
import { toast } from "react-toastify";

const VerifyYourEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI);
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const email = useLocation()?.state;

  const resendLink = async () => {
    // Implement resend logic here
    const res = await resendVerifyLink(clientID as string, redirectURI as string)
    if (res?.ok) toast.success("Verification link has been resent to your provided email address")
    else toast.error("Unable to resend the verification link to the provided email")
  };

  const maskEmail = (email: string) => {
    // Split the email into the local part and domain part
    const [localPart, domain] = email?.split("@");

    // If the local part is too short, just return the original email
    if (localPart?.length < 5) {
      return email;
    }

    // Mask the part after the first 5 characters
    const maskedLocalPart = `${localPart?.substring(0, 5)}******`;

    // Return the masked email
    return `${maskedLocalPart}@${domain}`;
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen py-5 px-3">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[676px]">
            <h3 className="text-secondary text-xl md:text-2xl font-semibold mb-4">
              Verify your email{" "}
            </h3>
            <p className="text-base font-light text-secondary text-opacity-80 mb-4">
              To proceed, please verify your identity. We’ve sent a verification
              link to {maskEmail(email) || ""}.
            </p>
            <p className="text-base font-light text-secondary text-opacity-80 mb-4">
              Please check your inbox and click on the verification link. If you
              don’t see the email, check your spam folder or, you can <span className="text-blue-600 cursor cursor-pointer" onClick={resendLink}>resend link.</span>
            </p>
            <p className="text-base font-light text-secondary text-opacity-80">
              Thank You
            </p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default VerifyYourEmail;
