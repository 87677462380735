import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";
import {
  initNewPasswordForm,
  initNewPasswordFormErrors,
} from "../../constants/initialForms";
import { ERR_MSGS } from "../../constants/errorMsgs";
import { regexPatterns } from "../../constants/regexPatterns";
import RequiredAsterisk from "../ui/icons/RequiredAsterisk";
import { createNewPassword } from "../../services/auth.service";
import { QUERY_PARAMS_KEY } from "../../constants/keys";
import { toast } from "react-toastify";

const NewPassword = () => {
  const [newPasswordForm, setNewPasswordForm] = useState(initNewPasswordForm);
  const [newPasswordFormErrors, setNewPasswordFormErrors] = useState(
    initNewPasswordFormErrors
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(QUERY_PARAMS_KEY?.TOKEN) || "";
  const redirectURI = urlParams.get("redirect_uri") || "";
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID) || "";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setNewPasswordForm((prev) => ({ ...prev, [name]: value }));
    // setNewPasswordFormErrors((prev) => ({ ...prev, setNewPasswordError: false }));
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: string } = {
      newPassword: "newPasswordError",
      confirmPassword: "confirmPasswordError",
    };
    const errorKey = fieldMappings[name];
    if (errorKey) {
      setNewPasswordFormErrors((prev) => ({
        ...prev,
        [errorKey]: "",
      }));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    if (
      name === "confirmPassword" &&
      newPasswordForm?.confirmPassword !== newPasswordForm?.newPassword
    ) {
      setNewPasswordFormErrors((prev) => ({
        ...prev,
        confirmPasswordError: ERR_MSGS.Password_Does_Not_Match,
      }));
    }
    const fieldMappings: { [key: string]: { key: string; errorMsg: string } } =
      {
        newPassword: {
          key: "newPasswordError",
          errorMsg: ERR_MSGS.Invalid_Password_Pattern,
        },
      };
    const fieldMapping = fieldMappings[name];
    if (fieldMapping) {
      const { key, errorMsg } = fieldMapping;
      const { value } = event?.target;
      const regexPatternFind: { [key: string]: RegExp } = {
        newPassword: regexPatterns?.password, // Password pattern
      };

      if (name in regexPatternFind && !regexPatternFind[name].test(value)) {
        setNewPasswordFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleSubmit = async () => {
    const reqBody = {
      token: token,
      newPassword: newPasswordForm?.newPassword,
    };

    const res = await createNewPassword(reqBody);
    if (res?.ok) {
      toast.success("Password Updated Successfully");
      navigate(
        `/sign-in?${clientID ? "client_id=" + clientID : ""}${
          redirectURI && "&redirect_URI=" + redirectURI
        }`
      );
    }
    // else setCreateAccFormErrors((prev) => ({ ...prev, signUpError: true }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const noError = Object.values(newPasswordFormErrors).every(
      (value) => value === ""
    );

    if (!noError) return;
    else handleSubmit();
  };

  const handleNavToSignUp = () => {
    return `/register-option${clientID ? "?client_id=" + clientID : ""}${
      redirectURI ? "&redirect_URI=" + redirectURI : ""
    }`;
  };

  useEffect(() => {
    const isFieldEmpty = Object.values(newPasswordForm).some(
      (value) => value === ""
    );

    const noError = Object.values(newPasswordFormErrors).every(
      (value) => value === ""
    );

    if (!isFieldEmpty && noError) setIsDisabled(false);
    else setIsDisabled(true);
  }, [newPasswordForm, newPasswordFormErrors]);

  return (
    <>
      <div className="flex justify-center items-center min-h-screen overflow-y-auto">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-5 md:px-16 rounded-[20px] max-w-[448px]">
            <h3 className="text-secondary text-2xl font-semibold mb-6">
              Set your password
            </h3>

            <form onSubmit={onSubmit}>
              <label className="text-base text-secondaryVariant">
                New Password
                <RequiredAsterisk />
              </label>
              <div className="relative mb-4 mt-2">
                <input
                  type={showPassword ? "text" : "Password"}
                  name="newPassword"
                  placeholder="Enter Your Password"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <button
                  className="absolute right-2 top-[17px]"
                  type="button"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <LuEyeOff className="w-4 h-5 text-secondary" />
                  ) : (
                    <LuEye className="w-4 h-5 text-secondary" />
                  )}
                </button>
                {newPasswordFormErrors?.newPasswordError && (
                  <p className="text-red-600 pl-5">
                    {newPasswordFormErrors?.newPasswordError}
                  </p>
                )}
              </div>
              <label className="text-base text-secondaryVariant">
                Confirm Password
                <RequiredAsterisk />
              </label>
              <div className="relative mt-2 mb-7">
                <input
                  type={showConfirmPassword ? "text" : "Password"}
                  name="confirmPassword"
                  placeholder="Re-enter Your Password"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <button
                  className="absolute right-2 top-[17px]"
                  type="button"
                  onClick={handleShowConfirmPassword}
                >
                  {showConfirmPassword ? (
                    <LuEyeOff className="w-4 h-5 text-secondary" />
                  ) : (
                    <LuEye className="w-4 h-5 text-secondary" />
                  )}
                </button>
                {newPasswordFormErrors?.confirmPasswordError && (
                  <p className="text-red-600 pl-5">
                    {newPasswordFormErrors?.confirmPasswordError}
                  </p>
                )}
              </div>
              <button
                className={`btnPrimary ${isDisabled ? "opacity-50" : ""}`}
                type="submit"
                disabled={isDisabled}
              >
                Set Password
              </button>{" "}
              <p className="flex items-center justify-center gap-3 text-base text-secondaryVariant text-opacity-50 mt-6">
                Don’t have an account?{" "}
                <Link
                  to={handleNavToSignUp()}
                  className=" text-secondary font-medium"
                >
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
