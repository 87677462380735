import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";

import useOutsideClick from "../../utils/useOutsideClick";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleNavToForgotPassword: () => string;
}

const ResetPasswordModal: React.FC<ModalProps> = ({ isOpen, onClose, handleNavToForgotPassword }) => {
  const navigate = useNavigate()
  const resetPassRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(resetPassRef, onClose);

  const handleNavToResetPassword = () => {
    onClose();
    navigate(handleNavToForgotPassword())
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="bg-white p-3 pb-9 rounded-3xl shadow-lg w-[827px] space-y-6"
        ref={resetPassRef}
      >
        {/* <div className="flex items-center justify-end">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div> */}

        <div className="p-6 bg-secondaryVariantNatural rounded-lg flex justify-between items-center">
          <div className="max-w-[406px] space-y-4">
            <h2 className="text-[28px] text-secondary font-bold">
              Welcome <br /> to Child Care{" "}
              <span className="text-primary italic">Forward!</span>
            </h2>
            <p className="text-lg text-secondaryVariant">
              We’re excited to introduce you to a more efficient and
              user-friendly experience. To ensure the security of your account,
              we ask that you{" "}
              <strong className="font-bold"> reset your password.</strong>
            </p>
          </div>
          <div>
            <img src="../images/reset-password-svg.svg" alt="reset password" />
          </div>
        </div>
        <div className="flex flex-col items-start gap-12 px-6">
          <div>
            <h3 className="text-secondary text-2xl font-bold mb-3">
              Why Reset?{" "}
            </h3>
            <p className="text-lg text-secondaryVariant">
              <strong className="font-semibold text-secondary me-1">
                Enhanced Security:
              </strong>
              Your safety is our priority, and this step helps protect your
              account.
            </p>
            <p className="text-lg text-secondaryVariant">
              <strong className="font-semibold text-secondary me-1">
                {" "}
                New Features:
              </strong>
              Discover the improved functionalities designed just for you!
            </p>
          </div>
          <div className="text-lg text-secondaryVariant">
            <strong className="font-semibold block text-secondary">
              {" "}
              Ready to Get Started?
            </strong>
            Click the button below to reset your password and unlock your new
            experience.{" "}
          </div>
          <span className="text-lg text-secondaryVariant block">
            Thank you for being a valued member of our community!
          </span>
          <div className="flex items-center gap-4 w-full">
            <button className="btnPrimary max-w-[200px] flex justify-center" onClick={handleNavToResetPassword}>
              Rest Password
            </button>
            <button className="btnSimple max-w-[132px]" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
