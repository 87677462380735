import { createSlice } from "@reduxjs/toolkit";
import { Label } from "../../types/ILabels";
interface Init {
  Labels: Label[] | null;
}
const initialState: Init = {
  Labels: [
    {
      _id: "",
      label: "",
      message: "",
    },
  ],
};

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setLabels: (state, action) => {
      // console.log("action payload: ", action?.payload);
      state.Labels = action.payload;
    },
  },
});

export const { setLabels } = labelsSlice.actions;

export default labelsSlice.reducer;
